@import url('https://fonts.googleapis.com/css2?family=Finlandica:ital,wght@0,400;0,500;1,400;1,500&display=swap');

$primary-color: colorFromEnv('REACT_APP_PRIMARY_COLOR');
$primary-color-mid: rgba($primary-color, 0.5);
$primary-color-lite: rgba($primary-color, 0.1);
$info-color: colorFromEnv('REACT_APP_INFO_COLOR');
$dark-color: colorFromEnv('REACT_APP_DARK_COLOR');

$theme-colors: (
  primary: $primary-color,
  primary-light: $primary-color-lite,
  info: $info-color,
  dark: $dark-color
);

$border-radius: 1.2rem;
$border-radius-lg: 1.2rem;
$border-radius-sm: 1.2rem;
$enable-shadows: true;

html,
body,
#root {
  height: 100%;
  font-family: 'Finlandica', sans-serif;
}

.modal {
  font-family: 'Finlandica', sans-serif;
}

.hover-light:hover {
  background-color: $primary-color-lite;
  cursor: pointer;
}

.left-bar {
  border-left: 10px solid $dark-color;
}

.hover-dark:hover {
  background-color: $primary-color-mid;
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hover-icon:hover {
  color: $primary-color;
  cursor: pointer;
}

@import '~bootstrap/scss/bootstrap';

.switch-toggle {
  float: right;
}
.switch-toggle input {
  position: absolute;
  opacity: 0;
}
.switch-toggle input + label {
  padding: 2px;
  margin-bottom: 0px;
  border-radius: 0.25rem;
  margin-right: 8px;
}

.label-on-hover:hover {
  cursor: pointer;
  background-color: map-get($map: $theme-colors, $key: success);
  color: #fff;
}

.label-na-hover:hover {
  cursor: pointer;
  background-color: map-get($map: $theme-colors, $key: info);
  color: #fff;
}

.label-off-hover:hover {
  cursor: pointer;
  background-color: map-get($map: $theme-colors, $key: danger);
  color: #fff;
}

.switch-toggle input:checked + .label-on {
  background: map-get($map: $theme-colors, $key: success);
  color: #fff;
}

.switch-toggle input:checked + .label-na {
  background: map-get($map: $theme-colors, $key: info);
  color: #fff;
}

.switch-toggle input:checked + .label-off {
  background: map-get($map: $theme-colors, $key: danger);
  color: #fff;
}

.xxlargeFont {
  font-size: 22px;
}

.xlargeFont {
  font-size: 18px;
}

.largeFont {
  font-size: 16px;
}

.midFont {
  font-size: 14px;
}

.smallFont {
  font-size: 12px;
}

.tinyFont {
  font-size: 10px;
}

.underline {
  text-decoration: underline;
}

.fade-in {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}

.rounded-border {
  border: 1px solid #ccc;
  border-radius: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

/* Add this CSS to your stylesheet */
.fullscreen-modal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: none;
}

.fullscreen-modal .modal-content {
  height: 100%;
  border-radius: 0;
}

.gap-1 {
  gap: 4px;
}

.gap-2 {
  gap: 8px;
}

.fw-bold {
  font-weight: 700;
}

.hover {
  cursor: pointer;
}


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


.hide-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

@import './components/SlidingSideBar/bar';

@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
