/* Map.css */

/* Finish Transformation button default */
.finish-transformation-container {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 5px;
  width: auto;
}

.floating-transformation-menu {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Move to the bottom-left of the screen */
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px; /* Fixed width */
}

.floating-transformation-menu h5 {
  margin-bottom: 10px;
}

.floating-transformation-menu label {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.floating-transformation-menu input[type='range'] {
  width: 100%;
}

.floating-transformation-menu button {
  margin-top: 15px; /* Ensure button stays below the sliders */
  width: 100%; /* Make button full-width for consistency */
}

/* Media query for small screens (e.g. phones, tablets) */
@media (max-width: 768px) {
  .finish-transformation-container {
    left: 10px;
    transform: translateX(0); /* Align to the left on small screens */
  }
}
